<template>
  <v-tab-item :transition="false" :value="tabHref">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="12" sm="6" xl="3">
          <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
            <v-text-field
              :label="'Search in Intensity figures'"
              color="#686868"
              class="search-field rounded-0 pt-0"
              height="44"
              light
              hide-details="auto"
              background-color="#F7F7F7"
              filled
              solo
              dense
              prepend-inner-icon="mdi-magnify"
              flat
              clearable
              @change="(val) => updateSearch(val)"
            ></v-text-field>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn
            large
            class="align-self-stretch align-self-sm-center mt-5 mr-4 mt-sm-0"
            elevation="0"
            tile
            color="#F25D3B"
            dark
            @click="createIntensityFigure"
          >
            <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
            {{ $t("common.actions.create") }}
          </v-btn>
          <IntensityFigureForm
            v-model="showForm"
            :intensityFigure="editingIntensityFigure"
          ></IntensityFigureForm>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="intensityFigures"
            @dblclick:row="(event, { item }) => setEditingIntensityFigure(item)"
          >
            <template #item.dateRange="{ item }">
              <div class="d-flex flex-row align-center">
                {{ item.dateRangeFrom }}
                <ArrowRightIcon
                  class="mx-2"
                  width="12px"
                  height="12px"
                ></ArrowRightIcon>
                {{ item.dateRangeTo }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>

<script>
import IntensityFigureForm from "../IntensityFigureForm.vue";
import ArrowRightIcon from "../../../assets/svg/arrow-right-dark-20px.svg";

export default {
  components: { IntensityFigureForm, ArrowRightIcon },
  props: {
    tabHref: String,
    tabId: Number,
    intensityFigures: Array,
  },
  data() {
    return {
      showForm: false,
      headers: [
        {
          text: this.$t("pages.settings.intensityFigures.table.id"),
          value: "id",
        },
        {
          text: this.$t("pages.settings.intensityFigures.table.name"),
          value: "name",
        },
        {
          text: this.$t("pages.settings.intensityFigures.table.dateRange"),
          value: "dateRange",
        },
      ],
      editingIntensityFigure: null,
    };
  },
  methods: {
    setEditingIntensityFigure(item) {
      this.editingIntensityFigure = item;
      this.showForm = true;
    },
    createIntensityFigure() {
      this.editingIntensityFigure = null;
      this.showForm = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
