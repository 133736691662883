<template>
  <dialog-form
    :show-dialog="show"
    @showStateChanged="show = $event"
    max-width="1000px"
    persistent
  >
    <dialog-form-header
      :title="$t('pages.settings.intensityFigures.form.title')"
      :showDismiss="true"
      @onDismiss="show = false"
    ></dialog-form-header>
    <dialog-form-section-one-col>
      <dialog-form-text-input
        :title="$t('pages.settings.intensityFigures.form.intensityFigureName')"
        :value="formData?.name"
        @input="(val) => (formData.name = val)"
      ></dialog-form-text-input>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col>
      <v-row>
        <v-col>
          <dialog-form-text-input
            :title="
              $t('pages.settings.intensityFigures.form.numberMetricDenominator')
            "
            :value="formData?.number"
            @input="(val) => (formData.number = val)"
          ></dialog-form-text-input>
        </v-col>
        <v-col>
          <dialog-form-input-wrapper
            :title="$t('pages.settings.intensityFigures.form.dateRange')"
          >
            <date-range-picker
              :fromDate="formData?.dateRangeFrom"
              :toDate="formData?.dateRangeTo"
              @change="(date) => setDateRange(date)"
              class="px-0"
              buttonStyle="padding: 0;"
            >
              <dialog-form-date-selector
                :value="new Date(formData?.dateRangeFrom)"
                class="pr-3"
                readonly
              ></dialog-form-date-selector>
              <ArrowRightIcon width="14px" height="14px"></ArrowRightIcon>
              <dialog-form-date-selector
                :value="new Date(formData?.dateRangeTo)"
                class="pl-3"
                readonly
              ></dialog-form-date-selector>
            </date-range-picker>
          </dialog-form-input-wrapper>
        </v-col>
      </v-row>
      <h3 class="mb-4">
        {{ $t("pages.settings.intensityFigures.form.chooseEmissons") }}
      </h3>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in items" :key="i">
          <v-expansion-panel-header>
            <div class="d-flex flex-row align-center">
              <v-checkbox
                hide-details
                class="mt-0"
                v-model="item.checked"
                :indeterminate="item.indeterminate"
                @click.stop="toggleCheckbox(item)"
                :label="item.label"
              ></v-checkbox>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="ml-8" v-for="(child, j) in item.children" :key="j">
              <v-checkbox
                hide-details
                v-model="child.checked"
                :indeterminate="child.indeterminate"
                @click.stop="toggleCheckbox(child)"
                :label="child.label"
              ></v-checkbox>
              <div v-if="child.children" class="ml-8">
                <v-checkbox
                  v-for="(grandchild, k) in child.children"
                  :key="k"
                  hide-details
                  v-model="grandchild.checked"
                  :indeterminate="grandchild.indeterminate"
                  @click.stop="toggleCheckbox(grandchild, true)"
                  :label="grandchild.label"
                ></v-checkbox>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col>
      <v-checkbox
        :label="$t('common.actions.selectAll')"
        @change="(val) => selectAllItems(val)"
        :indeterminate="itemsIndeterminate"
        :value="allItemsSelected"
      ></v-checkbox>
    </dialog-form-section-one-col>
    <dialog-form-footer class="z-1">
      <template #left>
        <v-btn
          v-if="!intensityFigure?.id"
          @click="deleteIntensityFigure(intensityFigure?.id)"
          class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          text
          tile
          x-large
        >
          <v-icon class="mr-3" color="#686868">mdi-delete-outline</v-icon>
          {{ $t("common.actions.delete") }}
        </v-btn>
      </template>
      <template #right>
        <v-btn
          @click="show = false"
          class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          text
          tile
          x-large
        >
          <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
          {{ $t("common.actions.cancel") }}
        </v-btn>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
          color="#F25D3B"
          elevation="0"
          tile
          x-large
          @click="saveIntensityFigure()"
        >
          {{ $t("common.actions.save") }}
        </v-btn></template
      >
    </dialog-form-footer>
  </dialog-form>
</template>

<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormFooter from "../../Components/Dialog/DialogFormFooter.vue";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogFormDateSelector from "../../Components/Dialog/inputs/DialogFormDateSelector.vue";
import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DateRangePicker from "../../Components/Inputs/DateRangePicker.vue";
import ArrowRightIcon from "../../assets/svg/arrow-right-dark-20px.svg";

export default {
  components: {
    DialogForm,
    DialogFormHeader,
    DialogFormSectionOneCol,
    DialogFormTextInput,
    DateRangePicker,
    DialogFormDateSelector,
    DialogFormInputWrapper,
    DialogFormFooter,
    ArrowRightIcon,
  },
  props: {
    value: Boolean,
    intensityFigure: Object,
  },
  data() {
    return {
      items: [
        {
          label: "Scopes",
          checked: false,
          indeterminate: false,
          children: [],
        },
        {
          label: "GHG categories",
          checked: false,
          indeterminate: false,
          children: [],
        },
        {
          label: "Emission factor sectors and categories",
          checked: false,
          indeterminate: false,
          children: [{
              label: "Consumer Goods and Services",
              checked: false,
              indeterminate: false,
              children: [
                {
                  label: "Food/Beverages/Tobacco",
                  checked: false,
                  indeterminate: false,
                  children: [],
                },
                {
                  label: "Paper Products",
                  checked: false,
                  indeterminate: false,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      formData: {
        name: "",
        number: "",
        dateRangeFrom: "",
        dateRangeTo: "",
        selectedEmissions: [],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    allItemsSelected() {
      return (
        this.items.filter((item) => item.checked).length === this.items.length
      );
    },
    itemsIndeterminate() {
      const selectedParents = this.items.filter((item) => item.checked).length;
      if (selectedParents > 0 && selectedParents < this.items.length) {
        return true;
      }
      return this.items.filter((item) => item.indeterminate).length > 0;
    },
  },
  methods: {
    setDateRange(dateRange) {
      this.formData.dateRangeFrom = dateRange.from;
      this.formData.dateRangeTo = dateRange.to;
    },

    updateChildCheckboxes(item, value) {
      item.checked = value;
      item.indeterminate = false;
      if (item.children) {
        item.children.forEach((child) => {
          this.updateChildCheckboxes(child, value);
        });
      }
    },

    updateParentCheckboxes() {
      const checkIndeterminate = (parent) => {
        let total = parent.children.length;

        let checkedCount = parent.children.filter(
          (child) => child.checked
        ).length;

        let indeterminateCount = parent.children.filter(
          (child) => child.indeterminate
        ).length;

        // Parent has no checked or indeterminate
        if (checkedCount === 0 && indeterminateCount === 0) {
          parent.checked = false;
          parent.indeterminate = false;
          // Parent has all checked
        } else if (checkedCount === total) {
          parent.checked = true;
          parent.indeterminate = false;
          // All other cases
        } else {
          parent.checked = false;
          parent.indeterminate = true;
        }
      };

      const updateParent = (parent) => {
        if (parent) {
          checkIndeterminate(parent);
          updateParent(parent.parent);
        }
      };

      // Parents
      this.items.forEach((parent) => {
        // Children
        parent.children.forEach((child) => {
          child.parent = parent;
          updateParent(parent);
          //Grandchildren
          child.children.forEach((grandChild) => {
            grandChild.parent = child;
            updateParent(child);
          });
        });
      });
    },

    toggleCheckbox(item) {
      this.updateChildCheckboxes(item, item.checked);
      // Parents
      this.updateParentCheckboxes();
    },
    selectAllItems(value) {
      this.items.forEach((item) => {
        this.updateChildCheckboxes(item, value);
      });
    },

    deleteIntensityFigure(id) {
      console.log("delete id: ", id);
    },
    saveIntensityFigure() {
      console.log("Save intensity figure");
    },
  },
  watch: {
    intensityFigure(to) {
      this.formData = to;
    },
  },
};
</script>

<style lang="scss" scoped></style>
