<template>
  <dialog-form
    :show-dialog="show"
    :loading="loading"
    @showStateChanged="show = $event"
    max-width="500px"
  >
    <dialog-form-header-stepper
      :on-dismiss="() => (show = false)"
      :steps="0"
    ></dialog-form-header-stepper>
    <dialog-form-section-one-col class="py-6 px-2">
      <v-row class="mx-0">
        <v-col class="px-0">
          <label class="d-block mb-2 text-center text-subtitle-2">
            {{ $t("pages.settings.integrationSettingsForm.choosePeriod") }}
          </label>
          <v-autocomplete
            v-model="syncPeriod"
            :items="formattedSyncPeriods"
            item-color="#F25D3B"
            hide-details
            solo
            class="rounded-0 mb-4"
            style="cursor: pointer"
            background-color="#F7F7F7"
            flat
            filled
            light
            :menu-props="{ top: false, offsetY: true }"
            color="red"
            :error-messages="form.errors.syncPeriod"
            @change="handleChange"
          >
            <template v-slot:item="{ item }">
              <div
                class="d-flex justify-space-between align-center clickable-item"
                style="width: 100%"
              >
                <template v-if="!item.isLink">
                  {{ item.text }}
                </template>
                <template v-else>
                  <a
                    :href="'mailto:' + 'support@verarca.com'"
                    @click.prevent="closeDialogAndOpenMail()"
                  >
                    {{ item.text }}
                  </a>
                </template>
                <v-btn
                  v-if="
                    isFreePlan && item.value !== 'TrialPeriod' && !item.isLink
                  "
                  tile
                  color="orange"
                  x-small
                  elevation="0"
                  class="font-weight-regular text-capitalize white--text"
                  type="submit"
                  @click.stop="openPaymentDialog()"
                >
                  Upgrade
                </v-btn>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="px-0">
          <v-checkbox
            v-model="form.useEntryCurrencyForAssets"
            :label="
              $t('pages.settings.integrationSettingsForm.alwaysEntryCurrency')
            "
          ></v-checkbox>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col>
      <v-row dense>
        <v-col cols="6" offset="3">
          <v-btn
            large
            class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 fill-width white--text"
            elevation="0"
            tile
            color="primary"
            @click="submit"
          >
            {{ $t("common.actions.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
    <payment-dialog
      v-model="paymentDialog"
      :publishableKey="publishableKey"
      :billingSubscription="billingSubscription"
      :paymentReturnUrl="paymentReturnUrl"
      :openedFromSettings="true"
      :paymentTierIds="paymentTierIds"
      :discountPercentage="discountPercentage"
    />
  </dialog-form>
</template>
<script>
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormHeaderStepper from "../../Components/Dialog/DialogFormHeaderStepper.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import PaymentDialog from "../../Components/Onboarding/PaymentDialog.vue";

export default {
  components: {
    DialogForm,
    DialogFormHeaderStepper,
    DialogFormSectionOneCol,
    PaymentDialog,
  },
  props: {
    value: Boolean,
    integration: Object,
    integrationSyncPeriods: Array,
    isFreePlan: Boolean,
    billingSubscription: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    discountPercentage: Number,
  },
  data() {
    return {
      loading: false,
      paymentDialog: false,
      syncPeriod: {},
      form: this.$inertia.form({
        syncPeriod: null,
        useEntryCurrencyForAssets: true,
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formattedSyncPeriods() {
      if (!this.integrationSyncPeriods) return [];

      let periods = [];
      periods = this.integrationSyncPeriods.map((syncPeriod) => ({
        text: this.$t(
          `pages.settings.integrationSettingsForm.periods.${syncPeriod}`
        ),
        value: syncPeriod,
      }));

      if (!this.isFreePlan && this.integration?.syncPeriod !== "TrialPeriod") {
        periods = periods.filter((x) => x.value !== "TrialPeriod");
      }

      periods.push({
        text: "Need more data? Contact support@verarca.com",
        value: null,
        isLink: true,
      });

      if (
        this.integration &&
        !periods.some((period) => period.value === this.integration.syncPeriod)
      ) {
        periods.push({
          text: this.$t(
            `pages.settings.integrationSettingsForm.periods.${this.integration.syncPeriod}`
          ),
          value: this.integration.syncPeriod,
          isCurrentSelection: true,
        });
      }

      if (this.isFreePlan) {
        this.updateSyncPeriod();
      }

      return periods;
    },
  },
  methods: {
    submit() {
      if (!this.integration) return;

      // format value viewModel data correct before submit
      // We only need the value
      this.form.syncPeriod = this.syncPeriod.value;

      this.form.patch(
        this.route("settings.integrations.update", this.integration.id),
        {
          onSuccess: () => (this.show = false),
        }
      );
    },
    updateSyncPeriod() {
      if (this.integration) {
        this.form.syncPeriod = this.integration.syncPeriod;
      }
    },
    openPaymentDialog() {
      this.paymentDialog = true;
    },
    disableItem(item) {
      return this.isFreePlan && item.value !== "TrialPeriod";
    },
    allowItem() {
      return this.isFreePlan;
    },
    handleChange(newValue) {
      if (this.isFreePlan && newValue !== "TrialPeriod") {
        this.$nextTick(() => {
          this.syncPeriod = this.formattedSyncPeriods.find(
            (item) => item.value === "TrialPeriod"
          );
        });
        this.openPaymentDialog();
      }
    },
    closeDialogAndOpenMail() {
      window.location.href = "mailto:support@verarca.com";
      this.show = false;
    },
  },
  watch: {
    value(value) {
      if (!value || !this.integration) return;

      this.form = this.$inertia.form({
        syncPeriod: this.integration.syncPeriod,
        useEntryCurrencyForAssets: this.integration.useEntryCurrencyForAssets,
      });
      this.syncPeriod = this.integration.syncPeriod;
    },
  },
};
</script>
<style scoped>
.orange-upgrade-button {
  background-color: #f25d3b; /* Orange color */
  color: #ffffff; /* White text color */
  border: none;
  width: 10%; /* Full width */
  height: 10%; /* Full height */
}
</style>
