<template>
  <div>
    <v-btn
      text
      light
      tile
      class="text-none font-weight-medium px-2 px-xl-4"
      color="#686868"
      @click="() => (show = !show)"
      ><slot name="trigger-button"></slot>
    </v-btn>
    <dialog-form
      :showDialog="show"
      @showStateChanged="(state) => (show = state)"
    >
      <dialog-form-header
        :title="$t('pages.assets.export.export')"
      ></dialog-form-header>
      <dialog-form-section-one-col>
        <v-row>
          <v-col>
            <dialog-form-title
              :title="$t('pages.assets.export.whichAssets')"
            ></dialog-form-title>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  column
                  mandatory
                  @change="setAssetsSelection"
                  v-model="selectedAssetOption"
                >
                  <v-radio value="All">
                    <template v-slot:label>
                      <div class="pr-2">
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.all") }}
                        </strong>
                      </div>
                      <div>({{ allAssetsNumber ?? "Loading" }} assets)</div>
                    </template>
                  </v-radio>
                  <v-radio value="Filtered assets">
                    <template v-slot:label>
                      <div class="pr-2">
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.filtered") }}
                        </strong>
                      </div>
                      <div>({{ filteredAssetsNumber }} assets)</div>
                    </template>
                  </v-radio>
                  <v-radio value="Selected assets">
                    <template v-slot:label>
                      <div class="pr-2">
                        <strong>
                          {{ $t("pages.assets.export.assetOptions.selected") }}
                        </strong>
                      </div>
                      <div>({{ selectedAssetsNumber }} assets)</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <dialog-form-title
              :title="$t('pages.assets.export.withAttributes')"
            ></dialog-form-title>
            <v-row>
              <v-col>
                <v-radio-group
                  column
                  mandatory
                  @change="setAttributesSelection"
                >
                  <v-radio value="All">
                    <template v-slot:label>
                      <div class="pr-2">
                        <strong>
                          {{ $t("pages.assets.export.attributeOptions.all") }}
                        </strong>
                      </div>
                      <div>
                        ({{
                          $t("pages.assets.export.attributeAmount", {
                            amount: allAttributesNumber,
                          })
                        }})
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="Selected attributes">
                    <template v-slot:label>
                      <div class="pr-2">
                        <strong>
                          {{
                            $t("pages.assets.export.attributeOptions.selected")
                          }}
                        </strong>
                      </div>
                      <div>
                        ({{
                          $t("pages.assets.export.attributeAmount", {
                            amount: selectedAttributesNumber,
                          })
                        }})
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <dialog-form-title
              :title="$t('pages.assets.export.inFormat')"
            ></dialog-form-title>
            <dialog-form-select-input
              id="ExportType"
              v-model="selectedExportOption"
              :items="exportOptions"
              item-text="text"
              item-value="id"
              :placeholder="$t('pages.assets.export.exportTo')"
              :title="$t('pages.assets.export.exportTo')"
              solo
              dense
            ></dialog-form-select-input>

            <dialog-form-select-input
              id="ExportFileType"
              v-model="selectedFileExportOption"
              :items="exportFileOptions"
              item-text="text"
              item-value="id"
              :placeholder="$t('pages.assets.export.exportToFileType')"
              :title="$t('pages.assets.export.exportToFileType')"
              solo
              dense
              @change="setSelectedExportType"
            ></dialog-form-select-input>

            <template v-if="selectedFileExportOption === 2">
              <div>
                <strong>{{ $t("pages.assets.export.csvLocalization") }}</strong>
              </div>
              <v-select
                id="CsvLocalizationType"
                v-model="selectedCsvLocalizationOption"
                :items="csvLocalizationOptions"
                item-text="text"
                item-value="id"
                :label="$t('pages.assets.export.csvLocalization')"
                solo
                dense
                @change="setSelectedCsvLocalizationType"
              ></v-select>
            </template>
          </v-col>
        </v-row>
      </dialog-form-section-one-col>
      <v-card-actions class="py-6 px-6 d-flex flex-column-reverse flex-md-row">
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 align-self-stretch"
          color="#686868"
          elevation="0"
          tile
          dark
          x-large
          text
          @click="show = false"
        >
          <v-icon color="#686868">mdi-close</v-icon>
          <p class="mb-0">
            {{ $t("common.actions.close") }}
          </p>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none ml-0 mb-4 mb-md-0 ml-md-4 px-6 align-self-stretch"
          color="#266663"
          dark
          elevation="0"
          tile
          x-large
          @click="exportAssets()"
          :disabled="loading"
          :loading="exportButtonLoading"
        >
          <v-icon class="white--text">mdi-chevron-right</v-icon>
          <p class="white--text mb-0">
            {{ $t("common.actions.export") }}
          </p>
        </v-btn>
      </v-card-actions>
      <message-dialog
        v-model="showMessageDialog"
        v-show="showMessageDialog"
        :confirm-message="confirmMessage"
      ></message-dialog>
    </dialog-form>
  </div>
</template>

<script>
import pickBy from "lodash/pickBy";
import { serialize } from "object-to-formdata";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogFormTitle from "../../Components/Dialog/output/DialogFormTitle.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import MessageDialog from "../../Components/MessageDialog.vue";

export default {
  mixins: [GlobalDateRange],
  components: {
    DialogForm,
    DialogFormHeader,
    DialogFormSectionOneCol,
    DialogFormTitle,
    DialogFormSelectInput,
    MessageDialog,
  },
  props: {
    value: Boolean,
    dialog: Boolean,
    attributes: Array,
    filteredAssets: Object,
    selectedAssets: Array,
    assetLayout: Object,
    organizationId: String,
    postFilters: Object,
    attributeFilters: Array,
  },
  watch: {
    show(to) {
      // when dialogue is triggered start getting data
      if (to) {
        this.allAssetsNumber = null;
        fetch(this.route("api.assets.total.count", {}))
          .then((response) => {
            if (!response.ok) {
              return Promise.reject();
            }
            return response.json();
          })
          .then((data) => {
            this.allAssetsNumber = data ?? 0;
          })
          .catch((error) => {
            console.warn("Could not load data", error);
          });
      }
    },
  },
  computed: {
    filteredAssetsNumber() {
      return this.filteredAssets.totalCount;
    },
    selectedAssetsNumber() {
      return this.selectedAssets.length;
    },
    allAttributesNumber() {
      return this.attributes.length;
    },
    selectedAttributesNumber() {
      return this.assetLayout.attributeIds.length;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    const exportOptions = [{ id: 1, text: "File" }];
    const exportFileOptions = [
      { id: 1, text: "xlsx" },
      { id: 2, text: "csv" },
    ];
    const csvLocalizationOptions = [
      { id: 1, text: "US" },
      { id: 2, text: "DK" },
    ];

    return {
      selectedExportOption: 1,
      exportOptions,
      selectedFileExportOption: 1,
      exportFileOptions,
      selectedCsvLocalizationOption: 1,
      csvLocalizationOptions,
      loading: false,
      search: "",
      exportFileType: exportFileOptions[0].text,
      csvLocalization: csvLocalizationOptions[0].text,
      assetsSelection: "",
      attributesSelection: "",
      localPostFilters: { ...this.postFilters },
      exportAllAttributes: 0,
      exportButtonLoading: false,
      selectedAssetOption: null,
      gettingTotalAssetCount: false,
      allAssetsNumber: null,
      dateRange: { from: null, to: null },
      showMessageDialog: false,
      confirmMessage: this.$t("pages.assets.export.confirmMessage"),
    };
  },
  methods: {
    exportAssets() {
      this.exportLoading = true;
      this.exportButtonLoading = true;

      let selectedAssetIds = [];
      let attributeFilters = [];

      let page = 1;
      let pageSize = 50; // number to process at a time in backend

      switch (this.attributesSelection) {
        case "All":
          this.exportAllAttributes = 1;
          this.localPostFilters.allAttributes = "true";
          break;
        case "Selected attributes":
          this.exportAllAttributes = 0;
          this.localPostFilters.allAttributes = this.postFilters.allAttributes;
          break;
        default:
          this.localPostFilters.allAttributes = "true";
      }

      switch (this.assetsSelection) {
        case "All":
          this.localPostFilters.allResults = "true";
          break;
        case "Filtered assets":
          this.localPostFilters.allResults = this.postFilters.allResults;
          this.localPostFilters.search = this.postFilters.search;
          page = 1;
          pageSize = this.filteredAssets.totalCount;
          attributeFilters = this.attributeFilters;
          break;
        case "Selected assets":
          this.localPostFilters.allResults = this.postFilters.allResults;
          this.localPostFilters.search = this.postFilters.search;
          page = this.filteredAssets.currentPage;
          pageSize = this.filteredAssets.pageSize;
          selectedAssetIds = this.getSelectedAssetIds();
          break;
        default:
          this.localPostFilters.allResults = "true";
      }

      let allAttributeIds = [];

      if (this.exportAllAttributes === 1) {
        this.attributes.forEach((element) => {
          allAttributeIds.push(element.id);
        });
      } else {
        this.assetLayout.attributeIds.forEach((attributeId) => {
          allAttributeIds.push(attributeId);
        });
      }

      let query = {
        dateRangeFrom: this.dateRange.from,
        dateRangeTo: this.dateRange.to,
      };

      let body = {
        page,
        pageSize,
        postFilters: pickBy(this.localPostFilters),
        organizationId: this.organizationId,
        assetIds: selectedAssetIds,
        fileType: this.exportFileType,
        allAttributeIds: allAttributeIds,
        localization: this.csvLocalization,
        exportAllAttributes: this.exportAllAttributes,
        attributeFilters: attributeFilters,
      };

      fetch(this.route("assets.export", query), {
        method: "POST",
        body: serialize(body, {
          indices: true,
        }),
      })
        // .then(async (res) => ({
        //   fileName: this.getFileNameFromHeader(
        //     res.headers.get("content-disposition")
        //   ),
        //   contentType: res.headers.get("content-type"),
        //   blob: await res.blob(),
        // }))
        // .then((fileData) => {
        //   const blob = new Blob([fileData.blob], {
        //     type: fileData.contentType,
        //   });

        //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(blob);
        //   } else {
        //     const blobUrl = URL.createObjectURL(blob);

        //     let link = document.createElement("a");
        //     link.href = blobUrl;
        //     link.download = fileData.fileName;
        //     link.click();

        //     setTimeout(() => {
        //       URL.revokeObjectURL(blobUrl);
        //     }, 250);
        //   }
        // })
        .finally(() => this.finalizeExport());
    },
    finalizeExport() {
      this.exportLoading = false;
      this.exportButtonLoading = false;
      this.show = false;
      this.showMessageDialog = true;
    },
    // Based on: https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
    getFileNameFromHeader(header) {
      let contentDispostion = header.split(";");
      const fileNameToken = `filename*=UTF-8''`;

      let fileName = null;
      for (let thisValue of contentDispostion) {
        if (thisValue.trim().indexOf(fileNameToken) === 0) {
          fileName = decodeURIComponent(
            thisValue.trim().replace(fileNameToken, "")
          );
          break;
        }
      }

      return fileName;
    },
    setSelectedExportType(value) {
      this.exportFileType = this.exportFileOptions.find(
        (x) => x.id === value
      ).text;
    },
    setSelectedCsvLocalizationType(value) {
      this.csvLocalization = this.csvLocalizationOptions.find(
        (x) => x.id === value
      ).text;
    },
    setAttributesSelection(value) {
      this.attributesSelection = value;
    },
    setAssetsSelection(value) {
      this.assetsSelection = value;
    },
    getSelectedAssetIds() {
      return this.selectedAssets.map((x) => x.id);
    },
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.dateRange = dateRange;
    });
  },
};
</script>
