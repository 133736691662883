<template>
  <dialog-form
    :show-dialog="show"
    :loading="currentStep > 1 && loading"
    @showStateChanged="show = $event"
    max-width="500px"
    persistent
  >
    <dialog-form-header-stepper
      :selected-step="currentStep"
      :steps="steps"
      :title="
        form.id
          ? $t('pages.settings.assetRuleForm.editTitle')
          : $t('pages.settings.assetRuleForm.title')
      "
      :subtitle="currentSubtitle"
      :on-dismiss="closeModal"
      @selectedStepUpdated="currentStep = $event"
    ></dialog-form-header-stepper>
    <dialog-form-section-one-col v-if="currentStep === 1" class="py-6 px-2">
      <trigger-section
        :property-name="form.triggerPropertyName"
        :value="form.triggerValue"
        :asset-rule-trigger-properties="triggerPropertyNames"
        :attributes="attributes"
        @propertyNameChanged="form.triggerPropertyName = $event"
        @valueChanged="form.triggerValue = $event"
      />
      <trigger-section
        v-if="form.secondTriggerLogicOperator"
        class="mt-4"
        :property-name="form.secondTriggerPropertyName"
        :value="form.secondTriggerValue"
        :asset-rule-trigger-properties="triggerPropertyNames"
        :attributes="attributes"
        :logic-operator="
          $t(
            `pages.settings.assetRuleForm.stepOne.logicOperators.${form.secondTriggerLogicOperator}`
          )
        "
        @propertyNameChanged="form.secondTriggerPropertyName = $event"
        @valueChanged="form.secondTriggerValue = $event"
        @deleteTrigger="deleteRule(2)"
        :show-operator-delete="!form.thirdTriggerLogicOperator"
      />
      <trigger-section
        v-if="form.thirdTriggerLogicOperator"
        class="mt-4"
        :property-name="form.thirdTriggerPropertyName"
        :value="form.thirdTriggerValue"
        :asset-rule-trigger-properties="triggerPropertyNames"
        :attributes="attributes"
        :logic-operator="
          $t(
            `pages.settings.assetRuleForm.stepOne.logicOperators.${form.thirdTriggerLogicOperator}`
          )
        "
        @deleteTrigger="deleteRule(3)"
        @propertyNameChanged="form.thirdTriggerPropertyName = $event"
        @valueChanged="form.thirdTriggerValue = $event"
      />
      <section-divider v-if="!form.thirdTriggerLogicOperator" left class="mt-1">
        <div class="d-flex">
          <v-btn
            text
            class="primary--text px-0 mr-4"
            min-width="0"
            @click="addTrigger('AND')"
          >
            {{ $t("pages.settings.assetRuleForm.stepOne.logicOperators.AND") }}
          </v-btn>
          <v-btn
            text
            class="primary--text px-0 mr-4"
            min-width="0"
            @click="addTrigger('OR')"
          >
            {{ $t("pages.settings.assetRuleForm.stepOne.logicOperators.OR") }}
          </v-btn>
          <v-btn
            text
            class="primary--text px-0 mr-1"
            min-width="0"
            @click="addTrigger('EXCEPT')"
          >
            {{
              $t("pages.settings.assetRuleForm.stepOne.logicOperators.EXCEPT")
            }}
          </v-btn>
        </div>
      </section-divider>
    </dialog-form-section-one-col>
    <template v-if="currentStep === 2">
      <dialog-form-section-one-col dense>
        <v-row dense>
          <v-col class="d-flex">
            <div class="d-flex flex-row align-center">
              <p
                class="text-subtitle-2 font-weight-regular grey--text text--lighten-1 my-2 flex-grow-1"
              >
                {{ $t("pages.settings.assetRuleForm.stepTwo.ignore") }}:
              </p>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-checkbox
              v-model="form.isIgnoreRule"
              :disabled="isIgnoreDisabled"
            >
              <template #label>
                <div
                  class="text-subtitle-2 font-weight-regular grey--text text--lighten-1 text-no-wrap"
                >
                  {{
                    $t("pages.settings.assetRuleForm.stepTwo.ignoreIfChecked")
                  }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" v-if="isIgnoreDisabled">
            <span class="ignore-info-text">
              {{
                $t(
                  "pages.settings.assetRuleForm.stepTwo.ignorePropertyMismatch"
                )
              }}
            </span>
          </v-col>
        </v-row>
      </dialog-form-section-one-col>
      <dialog-form-section-one-col v-if="!form.isIgnoreRule" class="py-6 px-2">
        <v-tabs v-model="actionsTab" show-arrows="" color="#F25D3B">
          <v-tab
            v-for="(tab, index) in attributeClassTabs"
            :key="index"
            class="body-2 text-none"
            >{{ tab.name }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="actionsTab">
          <template v-for="(tab, index) in attributeClassTabs">
            <tab-co2
              v-if="tab.id === fixedClasses.co2Id"
              :key="`co2-${tab.id}`"
              :tab-value="index"
              :initial-sectors="sectors"
              :attributes="attributes"
              :rerender-counter="rerenderCounter"
              :reload-emission-dropdowns="reloadEmissionDropdowns"
              :get-dynamic-value="getDynamicValue"
              :get-fixed-value="getFixedValue"
              :ghg-categories="ghgCategories"
              @setDynamicValue="setDynamicValue"
              @setFixedValue="setFixedValue"
              @emissionDropdownsReloaded="reloadEmissionDropdowns = false"
            />
            <tab-general
              v-else-if="tab.id === fixedClasses.generalId"
              :key="`general-${tab.id}`"
              :tab-value="index"
              :attributes="attributes"
              :currencies="currencies"
              :rerender-counter="rerenderCounter"
              :get-dynamic-action="getDynamicAction"
              :get-dynamic-value="getDynamicValue"
              :get-dynamic-second-value="getDynamicSecondValue"
              :get-fixed-value="getFixedValue"
              :settings="settings"
              @setDynamicValue="setDynamicValue"
              @setDynamicSecondValue="setDynamicSecondValue"
              @setFixedValue="setFixedValue"
              @removeDynamicAction="removeDynamicAction"
            />
            <tab-dynamic
              v-else
              :key="tab.id"
              :tab-value="index"
              :attribute-class="tab"
              :attributes="attributes"
              :currencies="currencies"
              :rerender-counter="rerenderCounter"
              :get-dynamic-value="getDynamicValue"
              :get-dynamic-second-value="getDynamicSecondValue"
              @setDynamicValue="setDynamicValue"
              @setDynamicSecondValue="setDynamicSecondValue"
            />
          </template>
        </v-tabs-items>
      </dialog-form-section-one-col>
    </template>
    <dialog-form-section-one-col v-if="currentStep === 3" class="py-6 px-2">
      <v-row class="mx-0 my-6">
        <v-col class="px-0">
          <v-autocomplete
            v-model="form.OnlyUnverified"
            :items="assetRuleScopes"
            item-color="primary"
            dense
            hide-details
            solo
          ></v-autocomplete>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
    <dialog-form-section-one-col>
      <v-row dense>
        <v-col cols="6" offset="3">
          <v-btn
            v-if="currentStep < steps"
            large
            class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 fill-width white--text"
            elevation="0"
            tile
            color="primary"
            :disabled="isButtonDisabled"
            @click="currentStep++"
          >
            {{ $t("common.actions.next") }}
          </v-btn>
          <v-btn
            v-else
            large
            class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 fill-width"
            elevation="0"
            tile
            color="primary"
            dark
            @click="submit"
          >
            {{ $t("common.actions.apply") }}
          </v-btn>
        </v-col>
      </v-row>
    </dialog-form-section-one-col>
  </dialog-form>
</template>
<script>
import { serialize } from "object-to-formdata";

import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogFormHeaderStepper from "../../Components/Dialog/DialogFormHeaderStepper.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import TriggerSection from "../../Components/AssetRule/TriggerSection.vue";
import SectionDivider from "../../Components/AssetRule/SectionDivider.vue";

import TabCo2 from "./AssetRuleForm/TabCo2.vue";
import TabGeneral from "./AssetRuleForm/TabGeneral.vue";
import TabDynamic from "./AssetRuleForm/TabDynamic.vue";

import {
  assetRuleActionProperty,
  fixedClasses,
} from "../../util/fixedAssetData";
import { removeTimezoneOffset } from "../../util/dateTime";

export default {
  components: {
    DialogForm,
    DialogFormHeaderStepper,
    DialogFormSectionOneCol,
    TriggerSection,
    SectionDivider,
    TabCo2,
    TabGeneral,
    TabDynamic,
  },
  props: {
    value: Boolean,
    assetRuleTriggerProperties: Array,
    assetRuleEntryTriggerProperties: Array,
    currencies: Array,
    assetRule: Object,
    settings: Object,
    ghgCategories: Array,
  },
  emits: ["setTab"],
  data() {
    return {
      currentStep: 1,
      form: this.$inertia.form({
        id: null,
        triggerPropertyName: this.assetRuleTriggerProperties[0] ?? null,
        triggerValue: null,
        secondTriggerPropertyName: null,
        secondTriggerValue: null,
        secondTriggerLogicOperator: null,
        thirdTriggerPropertyName: null,
        thirdTriggerValue: null,
        thirdTriggerLogicOperator: null,
        actions: [],
        isIgnoreRule: false,
        OnlyUnverified: false,
      }),
      actionsTab: 0,
      attributes: [],
      attributeClasses: [],
      sectors: [],
      assetRuleScopes: [
        {
          text: this.$t("pages.settings.assetRuleForm.stepThree.forAllAssets"),
          value: false,
        },
        {
          text: this.$t(
            "pages.settings.assetRuleForm.stepThree.forAllUnverifiedAssets"
          ),
          value: true,
        },
      ],
      loading: false,
      rerenderCounter: 0,
      fixedClasses,
      reloadEmissionDropdowns: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    steps() {
      return 3;
    },
    currentSubtitle() {
      switch (this.currentStep) {
        case 1:
          return this.$t("pages.settings.assetRuleForm.specifyTrigger");
        case 2:
          return this.$t("pages.settings.assetRuleForm.specifyAction");
        case 3:
          return this.$t("pages.settings.assetRuleForm.specifyScope");
        default:
          return "";
      }
    },
    isButtonDisabled() {
      const triggerProperty = this.form.triggerPropertyName;
      const triggerValue = (this.form.triggerValue ?? "").trim();

      const missingTriggerProp =
        !this.assetRuleTriggerProperties.includes(triggerProperty);
      const missingDimensionProp = !this.attributes.some(
        (x) => x.id === triggerProperty
      );

      switch (this.currentStep) {
        case 1:
          return (
            (missingTriggerProp && missingDimensionProp) || triggerValue === ""
          );
        case 2:
          return !this.form.actions.length && !this.form.isIgnoreRule;
        default:
          return false;
      }
    },
    attributeClassTabs() {
      if (!this.attributes || !this.attributeClasses) return [];

      if (!this.attributes.some((x) => x.attributeClassId == null))
        return this.attributeClasses;
      return [
        ...this.attributeClasses,
        { name: this.$t("pages.assets.form.noClass"), id: null },
      ];
    },
    defaultCurrencyId() {
      return this.$inertia.page.props.auth.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? null;
    },
    triggerProperties() {
      return [
        this.form.triggerPropertyName,
        this.form.secondTriggerPropertyName,
        this.form.thirdTriggerPropertyName,
      ];
    },
    logicOperators() {
      return [
        this.form.secondTriggerLogicOperator,
        this.form.thirdTriggerLogicOperator,
      ];
    },
    isIgnoreDisabled() {
      const triggerProperties = this.triggerProperties.filter((x) => !!x);
      const logicOperators = this.logicOperators.filter((x) => !!x);

      const hasEntryProperty = triggerProperties.some((x) =>
        this.assetRuleEntryTriggerProperties.includes(x)
      );

      const hasNonEntryProperty = triggerProperties.some(
        (x) => !this.assetRuleEntryTriggerProperties.includes(x)
      );

      const hasExceptOperator = logicOperators.some((x) => x === "EXCEPT");

      // Except logic cannot be enforced with asset properties due to how entry property ignore rules are applied
      return hasExceptOperator && hasEntryProperty && hasNonEntryProperty;
    },
    triggerPropertyNames() {
      let properties = this.assetRuleTriggerProperties.map((x) => ({
        text: x,
        value: x,
      }));

      const formattedDimensionProperties = this.attributes
        .filter((x) => x.attributeClassId === fixedClasses.dimensionsId)
        .map((x) => ({ text: x.name, value: x.id }));

      properties = properties.concat(formattedDimensionProperties);

      return properties;
    },
  },
  methods: {
    setForm(assetRule) {
      if (assetRule != null) {
        this.form.id = assetRule.id;

        this.form.triggerPropertyName = assetRule.triggerPropertyName;
        this.form.triggerValue = assetRule.triggerValue;

        this.form.secondTriggerPropertyName =
          assetRule.secondTriggerPropertyName;
        this.form.secondTriggerValue = assetRule.secondTriggerValue;
        this.form.secondTriggerLogicOperator =
          assetRule.secondTriggerLogicOperator;

        this.form.thirdTriggerPropertyName = assetRule.thirdTriggerPropertyName;
        this.form.thirdTriggerValue = assetRule.thirdTriggerValue;
        this.form.thirdTriggerLogicOperator =
          assetRule.thirdTriggerLogicOperator;

        this.form.actions = assetRule.actions;

        this.form.isIgnoreRule = assetRule.isIgnoreRule;
        this.form.OnlyUnverified = assetRule.OnlyUnverified;
      } else {
        this.form.id = null;

        this.form.triggerPropertyName =
          this.assetRuleTriggerProperties[0] ?? null;
        this.form.triggerValue = null;

        this.form.secondTriggerPropertyName = null;
        this.form.secondTriggerValue = null;
        this.form.secondTriggerLogicOperator = null;

        this.form.thirdTriggerPropertyName = null;
        this.form.thirdTriggerValue = null;
        this.form.thirdTriggerLogicOperator = null;

        this.form.actions = [];

        this.form.isIgnoreRule = false;
        this.form.OnlyUnverified = false;
      }
    },
    closeModal(confirmClose = true) {
      const confirmText = this.$t("pages.settings.assetRuleForm.confirmClose");

      const hasUserInteraction = this.currentStep > 1 || this.form.isDirty;

      if (hasUserInteraction && confirmClose && !confirm(confirmText)) return;

      this.show = false;

      this.currentStep = 1;
      this.form.reset();

      this.sectors = [];
      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];
    },
    submit() {
      const preparedForm = this.form.transform((data) =>
        serialize(
          {
            ...data,
            actions: [...data.actions.map((x) => ({ ...x, attribute: null }))],
          },
          {
            indices: true,
            dotsForObjectNotation: true,
            nullsAsUndefineds: true,
          }
        )
      );

      if (this.form.id) {
        preparedForm.put(this.route("asset-rules.update", this.assetRule.id), {
          onSuccess: () => {
            this.closeModal(false);
          },
          onFinish: () => {
            this.$emit("setTab");
          },
        });
      } else {
        preparedForm.post(this.route("asset-rules.store"), {
          onSuccess: () => {
            this.closeModal(false);
          },
          onFinish: () => {
            this.$emit("setTab");
          },
        });
      }
      this.$emit("ruleChanged");
    },
    getFixedValue(propertyName) {
      const action = this.form.actions.find(
        (x) => x.propertyName === propertyName
      );

      if (!action) return null;

      switch (action.propertyName) {
        case assetRuleActionProperty.category:
        case assetRuleActionProperty.emissionFactorName:
        case assetRuleActionProperty.region:
        case assetRuleActionProperty.sector:
        case assetRuleActionProperty.ghgCategory:
          return action.guidValue ?? null;
        default:
          return null;
      }
    },
    setFixedValue(propertyName, value) {
      if (!value) {
        this.form.actions = this.form.actions.filter(
          (x) => x.propertyName !== propertyName
        );
        return;
      }

      let action = this.form.actions.find(
        (x) => x.propertyName === propertyName
      );

      if (!action) {
        action = { propertyName };

        this.form.actions.push(action);
      }

      switch (action.propertyName) {
        case assetRuleActionProperty.category:
        case assetRuleActionProperty.emissionFactorName:
        case assetRuleActionProperty.region:
        case assetRuleActionProperty.sector:
        case assetRuleActionProperty.ghgCategory:
          action.guidValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    getDynamicAction(attributeId) {
      return this.form.actions.find((x) => x.attributeId === attributeId);
    },
    getDynamicValue(attributeId) {
      const action = this.getDynamicAction(attributeId);

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!action || !attribute) return null;

      switch (attribute.format) {
        case "Text":
        case "TextArea":
          return action.stringValue ?? null;
        case "Number":
        case "Percentage":
        case "Currency":
          return action.decimalValue ?? null;
        case "Date":
        case "DateTime":
          return action.dateTimeValue ?? null;
        case "Boolean":
          return action.boolValue ?? null;
        case "File":
          return action.newFiles ?? null;
        case "Select":
          return action.guidValue ?? null;
        default:
          return null;
      }
    },
    getDynamicSecondValue(attributeId) {
      const action = this.getDynamicAction(attributeId);

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!action || !attribute) return null;

      switch (attribute.format) {
        case "Currency":
          return action.stringValue ?? null;
        case "File":
          return action.files ?? null;
        default:
          return null;
      }
    },
    setDynamicValue(attributeId, value, remove = true) {
      if (remove && !value && !this.getDynamicSecondValue(attributeId)) {
        this.removeDynamicAction(attributeId);
        return;
      }

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!attribute) return;

      let action = this.form.actions.find((x) => x.attributeId === attributeId);

      if (!action) {
        action = { attributeId };

        this.form.actions.push(action);
      }

      switch (attribute.format) {
        case "Text":
        case "TextArea":
          action.stringValue = value;
          break;
        case "Number":
        case "Percentage":
          action.decimalValue = value;
          break;
        case "Currency":
          action.decimalValue = value;
          break;
        case "Date":
        case "DateTime":
          action.dateTimeValue = value ? removeTimezoneOffset(value) : value;
          break;
        case "Boolean":
          action.boolValue = value;
          break;
        case "File":
          action.newFiles = value;
          break;
        case "Select":
          action.guidValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    setDynamicSecondValue(attributeId, value, remove = true) {
      if (remove && !value && !this.getDynamicValue(attributeId)) {
        this.removeDynamicAction(attributeId);
        return;
      }

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!attribute) return;

      let action = this.form.actions.find((x) => x.attributeId === attributeId);

      if (!action) {
        action = { attributeId };

        this.form.actions.push(action);
      }

      switch (attribute.format) {
        case "Currency":
          action.stringValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    removeDynamicAction(attributeId) {
      this.form.actions = this.form.actions.filter(
        (x) => x.attributeId !== attributeId
      );
    },
    initializeResources() {
      this.loading = true;

      fetch(this.route("api.asset-rules.resources"))
        .then((res) => {
          if (!res.ok) return Promise.reject();

          return res.json();
        })
        .then((data) => {
          this.attributes = data.attributes;
          this.attributeClasses = data.attributeClasses;
          this.sectors = data.sectors;
        })
        .finally(() => (this.loading = false));
    },
    addTrigger(logicOperator) {
      if (!this.form.secondTriggerLogicOperator) {
        this.form.secondTriggerLogicOperator = logicOperator;
        return;
      }

      if (!this.form.thirdTriggerLogicOperator) {
        this.form.thirdTriggerLogicOperator = logicOperator;
        return;
      }
    },
    deleteRule(rule) {
      if (rule === 3) {
        this.form.thirdTriggerLogicOperator = null;
        this.form.thirdTriggerPropertyName = null;
        this.form.thirdTriggerValue = null;
      }
      if (rule === 2) {
        this.form.secondTriggerLogicOperator = null;
        this.form.secondTriggerPropertyName = null;
        this.form.secondTriggerValue = null;
      }
      return;
    },
  },
  watch: {
    assetRule: function (assetRule) {
      this.setForm(assetRule);

      if (assetRule) {
        this.reloadEmissionDropdowns = true;
      }
    },
    show(value) {
      if (value === false) {
        this.setForm(null);
        this.currentStep = 1;
        this.$emit("ruleChanged");
      }

      if (!value) return;

      this.initializeResources();
    },
    "form.isIgnoreRule": function (value) {
      this.form.OnlyUnverified = false;

      if (!value) return;

      this.form.actions = [];
    },
    isIgnoreDisabled(value) {
      if (!value) return;

      this.form.isIgnoreRule = false;
    },
  },
};
</script>
<style>
.ignore-info-text {
  font-size: 0.72rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}
</style>
