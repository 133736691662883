import menuItems from "./menuItems.json";
import layout from "./layout.json";
import notificationMenu from "./notificationMenu.json";
import requestSizeError from "./requestSizeError.json";
import fileMenu from "./fileMenu.json";
import contents from "./contents.json";
import assetDialog from "./assets/dialog.json";
import assetPreview from "./assets/preview.json";
import attributeActionMenu from "./attributes/actionMenu.json";
import attributeChooseAttributes from "./attributes/chooseAttributes.json";
import attributeFilters from "./attributes/filters.json";
import attributeFilterStatusLabels from "./attributes/filterStatusLabels.json";
import integrationChooseAccounts from "./integrations/chooseAccounts.json";
import integrationManualFileUpload from "./integrations/manualFileUpload.json";
import layoutOptions from "./layouts/options.json";
import mentionInput from "./mentionInput.json";
import onboarding from "./onboarding.json";
import payment from "./payment.json";
import integration from "./integration.json";
import amountChangeCard from "./cards/amountChange.json";
import co2CategoriesCard from "./cards/co2Categories.json";
import co2EmissionsCard from "./cards/co2Emissions.json";
import emissionsByMethodCard from "./cards/emissionsByMethod.json";
import cardTitles from "./cards/titles.json";
import top20OrganizationsCard from "./cards/top20Organizations.json";
import top20PurchasesCard from "./cards/top20Purchases.json";
import top20SuppliersCard from "./cards/top20Suppliers.json";
import top20Co2Categories from "./cards/top20Co2Categories.json";
import ghgCategoriesCard from "./cards/ghgCategories.json";
import chartCard from "./cards/chart.json";
import upgradeToProCard from "./cards/upgradeToPro.json";
import assetListFilter from "./assets/listFilter.json";
import accountingEntriesListFilter from "./accountingEntries/listFilter.json";
import accountingEntriesLayoutOptions from "./accountingEntries/layoutOptions.json";
import accountingEntriesFields from "./accountingEntries/fields.json";
import profile from "./profile.json";
import dateRangePicker from "./inputs/dateRangePicker.json";
import copilot from "./copilot.json";
import changePassword from "./changePassword.json";
import upgradeFromTrialBanner from "./banners/upgradeFromTrialBanner.json";
import reportTemplates from "./reportTemplates.json";

export default {
  menuItems,
  layout,
  notificationMenu,
  requestSizeError,
  fileMenu,
  contents,
  assets: {
    dialog: assetDialog,
    preview: assetPreview,
    listFilter: assetListFilter,
  },
  attributes: {
    actionMenu: attributeActionMenu,
    chooseAttributes: attributeChooseAttributes,
    filters: attributeFilters,
    filterStatusLabels: attributeFilterStatusLabels,
  },
  integrations: {
    chooseAccounts: integrationChooseAccounts,
    manualFileUpload: integrationManualFileUpload,
  },
  layouts: {
    options: layoutOptions,
  },
  mentionInput,
  onboarding,
  payment,
  integration,
  banners: {
    upgradeFromTrialBanner: {
      upgradeFromTrialMessage: upgradeFromTrialBanner.upgradeFromTrialMessage,
      upgradePlan: upgradeFromTrialBanner.upgradePlan,
    },
  },
  cards: {
    amountChange: amountChangeCard,
    chart: chartCard,
    co2Categories: co2CategoriesCard,
    co2Emissions: co2EmissionsCard,
    emissionsByMethod: emissionsByMethodCard,
    titles: cardTitles,
    top20Organizations: top20OrganizationsCard,
    top20Purchases: top20PurchasesCard,
    top20Suppliers: top20SuppliersCard,
    top20Co2Categories: top20Co2Categories,
    ghgCategories: ghgCategoriesCard,
    upgradeToPro: upgradeToProCard,
  },
  profile,
  inputs: {
    dateRangePicker,
  },
  copilot,
  changePassword,
  reportTemplates,
  accountingEntries: {
    listFilter: accountingEntriesListFilter,
    layoutOptions: accountingEntriesLayoutOptions,
    fields: accountingEntriesFields
  },
};
