<template>
  <div class="fill-height grow d-flex flex-column flex-nowrap">
    <v-container fluid class="py-4">
      <integration-settings-modal
        v-model="showIntegrationForm"
        :integration="selectedIntegration"
        :integration-sync-periods="integrationSyncPeriods"
        :isFreePlan="isFreePlan"
        :billingSubscription="billingSubscription"
        :publishableKey="publishableKey"
        :paymentReturnUrl="paymentReturnUrl"
        :paymentTierIds="paymentTierIds"
        :discountPercentage="discountPercentage"
      />
      <choose-accounts-modal
        v-model="showChooseAccountsModal"
        :financial-accounts="selectedIntegrationAccounts"
        :integration="accountsIntegration"
        :dynamicsInitializedIntegrationId="dynamicsInitializedIntegrationId"
      />
      <reset-integration-modal
        v-model="showResetIntegrationModal"
        :integration="resetIntegration"
        @reloadPageAfterReset="reloadPageAfterReset"
      />
      <integration-dynamics365-companies-modal
        v-model="showDynamics365CompaniesModal"
        :companies="companies"
        :dynamics-initialized-integration-id="dynamicsInitializedIntegrationId"
        :bc365RedirectToOnboarding="bc365RedirectToOnboarding"
      />
      <div class="fill-height fill-width page-container">
        <v-row>
          <v-col cols="12" sm="6" xl="3">
            <div class="flex-grow-1 white pa-4 pr-2 d-flex align-center">
              <v-text-field
                :label="$t('pages.settings.index.search')"
                color="#686868"
                class="search-field rounded-0 pt-0"
                height="44"
                light
                hide-details="auto"
                background-color="#F7F7F7"
                filled
                solo
                dense
                prepend-inner-icon="mdi-magnify"
                flat
                clearable
                @change="(val) => updateSearch(val)"
                :value="filters?.integrationSearch"
              ></v-text-field>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end">
            <v-btn
              large
              class="align-self-stretch align-self-sm-center mt-5 mr-4 mt-sm-0"
              elevation="0"
              tile
              color="primary"
              dark
              @click="() => (showForm = true)"
            >
              <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
              {{ $t("common.actions.create") }}
            </v-btn>

            <integration-only-modal
              v-model="showForm"
              v-show="showForm"
              ref="onboardingModalComponent"
              :settings="settings"
              :fiscal-years="fiscalYears"
              :depreciation-methods="depreciationMethods"
              :depreciation-starts="depreciationStarts"
              :currencies="currencies"
              :selected-step.sync="selectedOnboardingStep"
              @show-api-fields="handleShowApiFields"
              @show-integration="handleShowIntegration"
              @close-all="handleCloseAllPopups"
              @step-changed="selectedOnboardingStep = $event"
              @onAccountMappingSuccess="handleShowIntegration"
              @open:manual-file-upload-modal="openManualFileUploadModal()"
            ></integration-only-modal>

            <manual-file-upload-modal
              v-model="showManualFileUploadModal"
              v-show="showManualFileUploadModal"
              :integrationId="manualFileIntegrationId"
            />
          </v-col>
          <v-col
            v-if="
              integrationError &&
              $te(`common.integrations.errors.${integrationError}`)
            "
            cols="12"
            class="pt-0"
          >
            <v-alert type="error" class="mx-4 mb-0">
              {{ $t(`common.integrations.errors.${integrationError}`) }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              class="data-table"
              :headers="headers"
              :items="integrations?.data"
              :server-items-length="integrations?.totalCount"
              :options.sync="pagination"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
              hide-default-footer
            >
              <template #item.type="{ item }">
                {{
                  $te(`common.integrations.types.${item.type}`)
                    ? $t(`common.integrations.types.${item.type}`)
                    : item.type
                }}
              </template>
              <template #item.system="{ item }">
                {{
                  $te(`common.integrations.systems.${item.system}`)
                    ? $t(`common.integrations.systems.${item.system}`)
                    : item.system
                }}
              </template>
              <template #item.lastSynchronizedAt="{ item }">
                {{ formatLastSynchronizedAt(item) }}
              </template>
              <template #item.syncPeriod="{ item }">
                <span v-if="item.syncPeriod">
                  {{
                    $t(
                      `pages.settings.integrationSettingsForm.periods.${item.syncPeriod}`
                    )
                  }}
                </span>
              </template>
              <template #item.active="{ item }">
                <!--<ToggleButton
                  :value="item.disabledAt !== null"
                  :color="{ checked: '#F25D3B', unchecked: '#AEAEAE' }"
                  :switch-color="{ checked: '#fff', unchecked: '#fff' }"
                  :sync="true"
                  :labels="false"
                  :height="24"
                  :width="41"
                  class="mr-3"
                />-->
                <span
                  v-if="item.disabledAt === null"
                  :class="getClassForStatus(item.disabledAt)"
                >
                  {{ $t("pages.settings.index.fields.status.active") }}
                </span>
                <span
                  v-else-if="item.disabledAt !== null"
                  :class="getClassForStatus(item.disabledAt)"
                >
                  {{ $t("pages.settings.index.fields.status.inactive") }}
                </span>
                <span v-else :class="getClassForStatus(item.disabledAt)">
                  {{ $t("pages.settings.index.fields.status.pending") }}
                </span>
              </template>
              <template #item.edit="{ item }">
                <v-menu offset-y min-width="120px" class="px-4" left>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex justify-end">
                      <v-btn v-bind="attrs" v-on="on" icon
                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                      >
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="
                        item.disabledAt === null && item.system !== 'manual'
                      "
                      @click="syncIntegration(item)"
                      :disabled="isJobSchedulingDisabled(item)"
                    >
                      <v-list-item-title>
                        {{
                          $t(
                            "pages.settings.index.integrations.actions.syncNow"
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-else-if="item.disabledAt === null"
                      @click="openManualFileUploadModal(item)"
                    >
                      <v-list-item-title>
                        {{
                          $t(
                            "pages.settings.index.integrations.actions.uploadFile"
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.system === 'datacollect'"
                      @click="copyToClipboard(item)"
                    >
                      <v-list-item-title>
                        {{
                          $t(
                            "pages.settings.index.integrations.actions.copyApiKey"
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editIntegration(item)">
                      <v-list-item-title>
                        {{ $t("common.actions.edit") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openChooseAccountsModal(item)">
                      <v-list-item-title>
                        {{
                          $t(
                            "pages.settings.index.integrations.actions.accounts"
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.system !== 'uniconta'"
                      @click="navigateToEventLog(item.id)"
                    >
                      <v-list-item-title>
                        {{
                          $t(
                            "pages.settings.index.integrations.actions.eventLog"
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                    @click="resetIntegration(item)"
                    :disabled="isJobSchedulingDisabled(item)"
                  >
                    <v-list-item-title>
                      {{ $t("common.actions.reset") }}
                    </v-list-item-title>
                  </v-list-item> -->
                    <v-list-item
                      @click="openResetIntegrationModal(item)"
                      :disabled="isJobSchedulingDisabled(item)"
                    >
                      <v-list-item-title>
                        {{ $t("common.actions.reset") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="destroyIntegration(item.id)">
                      <v-list-item-title>
                        {{ $t("common.actions.delete") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <confirm-dialog
        v-model="showDestroyConfirmDialog"
        v-show="showDestroyConfirmDialog"
        :confirm-message="confirmDestroyMessage"
        :subtitle-message="destroyReminderMessage"
        @confirmed="handleDestroyConfirmation"
      ></confirm-dialog>
    </v-container>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import debounce from "lodash/debounce";
import { DateTime } from "luxon";
import qs from "qs";
import ConfirmDialog from "../../Components/ConfirmDialog.vue";
import ChooseAccountsModal from "../../Components/Integration/ChooseAccountsModal.vue";
import IntegrationOnlyModal from "../../Components/Onboarding/IntegrationOnlyModal.vue";
import GlobalDateRange from "../../mixins/GlobalDateRange.vue";
import { normalizeISOString } from "../../util/dateTime";
import IntegrationDynamics365CompaniesModal from "./IntegrationDynamics365CompaniesModal.vue";
import IntegrationSettingsModal from "./IntegrationSettingsModal.vue";
import ResetIntegrationModal from "./ResetIntegrationModal.vue";
import ManualFileUploadModal from "../../Components/Integration/ManualFileUploadModal.vue";

export default {
  components: {
    IntegrationSettingsModal,
    ChooseAccountsModal,
    IntegrationDynamics365CompaniesModal,
    IntegrationOnlyModal,
    ConfirmDialog,
    ResetIntegrationModal,
    ManualFileUploadModal,
  },
  mixins: [GlobalDateRange],
  layout: appLayout({
    title: "pages.integrations.title",
    pushContent: false,
  }),

  props: {
    isOrganizationGroup: Boolean,
    settings: Object,
    organization: Object,
    integrations: Object,
    integrationSyncPeriods: Array,
    installedIntegration: Object,
    integrationError: String,
    financialAccounts: Array,
    companies: Array,
    showCompanies: Boolean,
    dynamicsInitializedIntegrationId: String,
    billingSubscription: Object,
    publishableKey: String,
    paymentReturnUrl: String,
    paymentTierIds: Object,
    isFreePlan: Boolean,
    discountPercentage: Number,
    economicResetTimeoutMinutes: Number,
    dynamicsResetTimeoutMinutes: Number,
    unicontaResetTimeoutMinutes: Number,
    dataCollectResetTimeoutMinutes: Number,
    bc365RedirectToOnboarding: Boolean,
    fiscalYears: Array,
    depreciationMethods: Array,
    depreciationStarts: Array,
    showOnboardingDialog: Boolean,
    integrationInstalled: Boolean,
    currencies: Array,
  },

  data() {
    const searchParams = qs.parse(window.location.search.substring(1));
    return {
      pagination: {
        page: this.integrations?.currentPage,
        itemsPerPage: this.integrations?.pageSize,
        sortBy: [searchParams.integrationSortBy ?? "systemId"],
        sortDesc: [searchParams.integrationSortDesc === "true"],
      },
      filters: {
        integrationSearch: searchParams.integrationSearch ?? null,
      },
      showForm: false,
      showIntegrationForm: !!this.installedIntegration,
      selectedIntegration: this.installedIntegration
        ? { ...this.installedIntegration }
        : null,
      showChooseAccountsModal: false,
      showResetIntegrationModal: false,
      showManualFileUploadModal: false,
      manualFileIntegrationId: null,
      accountsIntegration: null,
      resetIntegration: null,
      selectedOnboardingStep: 1,

      showDestroyConfirmDialog: false,
      confirmDestroyMessage: this.$t(
        "pages.settings.index.integrations.confirmDelete"
      ),
      destroyReminderMessage: this.$t(
        "pages.settings.index.integrations.deleteReminder"
      ),
      destroyId: String,
    };
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.dateRange = dateRange;
    });
  },
  computed: {
    integrationsBindings() {
      return {
        organization: this.organization,
        integrations: this.integrations,
        integrationSyncPeriods: this.integrationSyncPeriods,
        installedIntegration: this.installedIntegration,
        integrationError: this.integrationError,
        financialAccounts: this.financialAccounts,
        companies: this.companies,
        showCompanies: this.showCompanies,
        dynamicsInitializedIntegrationId: this.dynamicsInitializedIntegrationId,
        settings: this.settings,
        isFreePlan: this.isFreePlan,
        billingSubscription: this.billingSubscription,
        publishableKey: this.publishableKey,
        paymentReturnUrl: this.paymentReturnUrl,
        paymentTierIds: this.paymentTierIds,
        discountPercentage: this.discountPercentage,
        economicResetTimeoutMinutes: this.economicResetTimeoutMinutes,
        dynamicsResetTimeoutMinutes: this.dynamicsResetTimeoutMinutes,
        unicontaResetTimeoutMinutes: this.unicontaResetTimeoutMinutes,
        dataCollectResetTimeoutMinutes: this.dataCollectResetTimeoutMinutes,
        bc365RedirectToOnboarding: this.bc365RedirectToOnboarding,
      };
    },
    showDynamics365CompaniesModal() {
      return this.showCompanies;
    },
    headers() {
      return [
        {
          text: this.$t("common.fieldNames.id"),
          value: "referenceKey",
        },
        {
          text: this.$t("pages.settings.index.fields.type"),
          value: "type",
          sortable: false,
        },
        {
          text: this.$t("pages.settings.index.fields.system"),
          value: "system",
          sortable: false,
        },
        {
          text: this.$t("pages.settings.index.fields.systemId"),
          value: "systemId",
        },
        { text: this.$t("pages.settings.index.fields.name"), value: "name" },
        {
          text: this.$t("pages.settings.index.fields.lastUpdated"),
          value: "lastSynchronizedAt",
        },
        {
          text: this.$t("pages.settings.index.fields.syncPeriod"),
          value: "syncPeriod",
        },
        {
          text: this.$t("pages.settings.index.fields.status.label"),
          value: "active",
        },
        { text: "", value: "edit" },
      ];
    },
    selectedIntegrationAccounts() {
      if (!this.accountsIntegration || !this.financialAccounts?.length)
        return [];

      return this.financialAccounts.filter(
        (x) => x.integrationId === this.accountsIntegration.id
      );
    },
  },
  methods: {
    reloadPageAfterReset(integrationId, iterator, max, delay = 15000) {
      this.$inertia.reload();

      const integration = this.integrations.data.find(
        (x) => x.id === integrationId
      );

      if (integration?.resetScheduledAt && iterator < max) {
        setTimeout(
          () => this.reloadPageAfterReset(iterator + 1, max, delay),
          delay
        );
      }
    },
    handleShowWarning() {
      this.onboardingDialog = false;
      this.onboardingWarning = true;
    },
    handleShowApiFields() {
      this.onboardingDialog = false;
      this.apiFieldsDialog = true;
    },
    handleShowIntegration() {
      this.onboardingDialog = false;
      this.integrationDialog = true;
    },
    handleContinueOnboarding() {
      this.onboardingWarning = false;
      this.apiFieldsDialog = false;
      this.onboardingDialog = true;
    },
    handleCloseAllPopups() {
      this.onboardingWarning = false;
      this.apiFieldsDialog = false;
      this.onboardingDialog = false;
    },
    updateSearch(value) {
      this.filters.integrationSearch = value;
    },
    getClassForStatus(disabledAt) {
      if (disabledAt === null) {
        return "status-active";
      } else if (disabledAt !== null) {
        return "status-inactive";
      } else {
        return "status-pending";
      }
    },
    changePage(options) {
      const existingQuery = qs.parse(window.location.search.substring(1));

      const query = {
        ...existingQuery,
        integrationPage: options.page,
        integrationPageSize: options.itemsPerPage,
        integrationSearch: this.filters?.integrationSearch,
        integrationSortBy: options.sortBy[0],
        integrationSortDesc: options.sortDesc[0],
      };

      this.$inertia.get(
        this.route("integrations.index", query),
        {},
        {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {},
        }
      );
    },
    formatDate(timestamp) {
      if (!timestamp) return null;

      const normalizedDate = normalizeISOString(timestamp);

      if (this.settings.defaultDateFormat === "YYYY-MM-DD") {
        return DateTime.fromISO(normalizedDate).toFormat("yyyy-MM-dd HH:mm:ss");
      } else if (this.settings.defaultDateFormat === "DD-MM-YYYY") {
        return DateTime.fromISO(normalizedDate).toFormat("dd-MM-yyyy HH:mm:ss");
      } else return null;
    },
    formatLastSynchronizedAt(integration) {
      if (integration.resetScheduledAt !== null)
        return this.$t("pages.settings.index.integrations.resetInProgress");

      if (integration.currentSyncStartTime !== null)
        return this.$t("pages.settings.index.integrations.syncInProgress");

      if (!integration.lastSynchronizedAt) return "";

      return this.formatDate(integration.lastSynchronizedAt);
    },
    isJobSchedulingDisabled(integration) {
      if (integration.currentSyncStartTime !== null) return true;

      if (integration.resetScheduledAt === null) return false;

      let resetTimeoutInMinutes = 0;

      switch (integration.system) {
        case "economic":
          resetTimeoutInMinutes = this.economicResetTimeoutMinutes;
          break;
        case "dynamics365":
          resetTimeoutInMinutes = this.dynamicsResetTimeoutMinutes;
          break;
        case "uniconta":
          resetTimeoutInMinutes = this.unicontaResetTimeoutMinutes;
          break;
        case "datacollect":
          resetTimeoutInMinutes = this.dataCollectResetTimeoutMinutes;
          break;
      }

      var scheduledTime = DateTime.fromISO(integration.resetScheduledAt, {
        zone: "UTC",
      });

      var timeDiff = scheduledTime.diffNow("minutes").minutes * -1;

      return timeDiff < resetTimeoutInMinutes;
    },
    syncIntegration(integration) {
      if (this.isJobSchedulingDisabled(integration)) return;

      this.$inertia.post(
        this.route("settings.integrations.sync", integration.id)
      );

      // Set timestamp for faster sync progress check
      localStorage.setItem("last-sync-trigger-timestamp", Date.now());
    },
    copyToClipboard(integration) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(integration.apiToken)
          .then(() => {
            console.log("Text copied to clipboard");
          })
          .catch((err) => {
            console.error("Error copying text: ", err);
          });
      } else {
        this.fallbackCopyTextToClipboard(integration.apiToken);
      }
    },
    destroyIntegration(id) {
      this.destroyId = id;
      this.showDestroyConfirmDialog = true;
    },
    handleDestroyConfirmation(status) {
      this.isConfirmed = status;
      if (status) {
        this.confirmDestroyIntegration();
      }
    },
    confirmDestroyIntegration() {
      this.$inertia.delete(
        this.route("settings.integrations.destroy", this.destroyId)
      );
      this.destroyId = "";
    },
    editIntegration(integration) {
      this.selectedIntegration = { ...integration };
      this.showIntegrationForm = true;
    },
    navigateToEventLog(id) {
      this.$inertia.get(
        this.route(
          "integration-entry-events.index",
          {
            integrationid: id,
            dateRangeFrom: this.dateRange.from,
            dateRangeTo: this.dateRange.to,
          },
          {}
        )
      );
    },
    openChooseAccountsModal(integration) {
      this.accountsIntegration = integration;
      this.showChooseAccountsModal = true;
    },
    openResetIntegrationModal(integration) {
      this.resetIntegration = integration;
      this.showResetIntegrationModal = true;
    },
    setDateRange(date) {
      this.resetDateRange = { ...date };
    },
    openManualFileUploadModal(integration) {
      if (integration) {
        this.manualFileIntegrationId = integration.id;
      } else {
        this.manualFileIntegrationId = null;
      }

      this.showManualFileUploadModal = true;
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: [],
          sortDesc: [],
        };

        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
    showIntegrationForm(value) {
      if (value) return;

      this.selectedIntegration = null;

      const existingQuery = qs.parse(window.location.search.substring(1));

      if (!existingQuery.installedIntegrationId) return;

      delete existingQuery.installedIntegrationId;

      const newUrl = this.route("integrations.index", existingQuery);

      window.history.replaceState(null, "", newUrl);
    },
    dateRange() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
}

.status-active:before,
.status-pending:before,
.status-inactive:before {
  content: "";
  vertical-align: middle;
  height: 6px;
  width: 6px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 100%;
}

.status-active:before {
  background-color: #1f7439;
}
.status-pending:before {
  background-color: #f2933b;
}
.status-inactive:before {
  background-color: #d32c2c;
}
.data-table :deep(tr) {
  height: 70px !important;
}
.lottie-player {
  height: 100px;
  width: 100px;
}
</style>
